import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API,
  EXAM_REPORTS,
  thunkHandler,
  UPLOAD_REPORT,
  USERS,
} from "../../config/api/api";
import { dashboardMessages } from "../../config/pageContents";

const initialState = {
  pendingImports: [],
  fileDetails: {},
  users: [],
  isLoading: false,
  userListLoading: true,
  dasboardMessage: {},
  reportUploadErr: [],
  reportUploadMsg: {},
  approveStatus: "Approve",
  serverError: false,
};

export const getExamReports = createAsyncThunk("reports/pending", (thunkAPI) =>
  thunkHandler(API.get(`${EXAM_REPORTS}?is_approved=false`), thunkAPI)
);
export const getReportDetails = createAsyncThunk(
  "report/details",
  (id, thunkAPI) =>
    thunkHandler(API.get(`${EXAM_REPORTS}?report_id=${id}`), thunkAPI)
);
export const getAllUsers = createAsyncThunk("all/users", (thunkAPI) =>
  thunkHandler(API.get(USERS), thunkAPI)
);
export const uploadReport = createAsyncThunk(
  "upload/report",
  (payload, thunkAPI) =>
    thunkHandler(API.post(UPLOAD_REPORT, payload), thunkAPI)
);
export const approveReport = createAsyncThunk(
  "approve/report",
  (payload, thunkAPI) => thunkHandler(API.post(EXAM_REPORTS, payload), thunkAPI)
);
export const imports = createSlice({
  name: "imports",
  initialState,
  extraReducers: {
    [getExamReports.pending]: (state, action) => {
      state.isLoading = true;
      state.dasboardMessage = {};
    },
    [getExamReports.fulfilled]: (state, action) => {
      state.pendingImports = action.payload.data;
      state.isLoading = false;
      state.dasboardMessage = action.payload.data.length
        ? dashboardMessages
        : {};
    },
    [getExamReports.rejected]: (state, action) => {
      state.pendingImports = [];
      state.isLoading = false;
    },
    [getReportDetails.pending]: (state, action) => {
      state.isLoading = true;
      state.serverError = false;
      state.approveStatus = "Approve";
    },
    [getReportDetails.fulfilled]: (state, action) => {
      state.fileDetails = action.payload.data;
      state.isLoading = false;
    },
    [getReportDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.serverError = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.users = action.payload.data.map((x) => ({
        value: x.user_id,
        title: x.username,
      }));
      state.userListLoading = false;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.userListLoading = false;
    },
    [uploadReport.pending]: (state, action) => {
      state.reportUploadErr = [];
    },
    [uploadReport.fulfilled]: (state, action) => {
      state.reportUploadMsg = {};
      state.reportUploadErr = [];
    },
    [uploadReport.rejected]: (state, action) => {
      state.reportUploadErr = action.payload.message
        ? [{ message: action.payload.message }]
        : action.payload.messages ?? [{ message: action.payload }];
    },
    [approveReport.pending]: (state, action) => {
      state.approveStatus = "Approving";
    },
    [approveReport.fulfilled]: (state, action) => {
      state.pendingImports = state.pendingImports.filter(
        (file) => file.id !== action.meta.arg.report_id
      );
      state.approveStatus = "Approved";
      state.fileDetails = { ...state.fileDetails, is_approved: true };
    },
    [approveReport.rejected]: (state, action) => {
      state.approveStatus = "Retry";
      state.fileDetails = {
        ...state.fileDetails,
        is_approved: "Failed to approve",
      };
    },
  },
});

// Action creators are generated for each case reducer function

export default imports.reducer;
