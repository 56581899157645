import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#004990",
      smudge: "#F3F8FF"
    },
    secondary: {
      main: "#FFFFFF",
    },
    success: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text:{
      primary: "rgb(0 0 0 / 64%)",
    }
  },
  success: "#19857b"
});
