import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { useHistory } from "react-router-dom";
import CustomDialog from "./dialogue";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/auth";

export default function BadgeIcon({ icon, linkTo = "", showAlert }) {
  const [popup, setPopUp] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLogOut = () => {
    window.location.href = "http://devindia.fpsb.org/"
    // dispatch(logout());
    // setPopUp(false);
    // history.push("/");
  };
  return (
    <>
      <CustomDialog
        open={popup}
        title="Exit to WordPress?"
        onClose={() => setPopUp(false)}
        Actions={() => (
          <>
            <Button autoFocus onClick={() => setPopUp(false)} color="primary" variant="outlined">
              No
            </Button>
            <Button onClick={handleLogOut} color="primary" variant="contained">
              Yes
            </Button>
          </>
        )}
      />
      <IconButton
        aria-label=""
        color="inherit"
        style={{ color: "white" }}
        onClick={() => (showAlert ? setPopUp(true) : history.push(linkTo))}
      >
        <Badge color="secondary">{icon}</Badge>
      </IconButton>
    </>
  );
}
