import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import MiniDrawer from "../mui-componenets/sidebar";
import AppHeader from "../mui-componenets/appBar";
import { useDispatch, useSelector } from "react-redux";
import { getUserMenu } from "../../redux/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    width: "calc(100% - 200px)",
  },
}));
export default function Layout({ children }) {
  const [sideBar, setSideBar] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { menu, navMenu } = useSelector((store) => store.auth);
  const onSideBarClose = (open) => setSideBar(open);

  useEffect(() => {
    dispatch(getUserMenu());
  }, []);

  return (
    <>
      <AppHeader onSideBarClose={onSideBarClose} navMenus={navMenu} />
      <div className="pages container">
        <div className={classes.root}>
          <CssBaseline />
          <MiniDrawer menu={menu} minimise={sideBar} />
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </>
  );
}
