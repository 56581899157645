import React from "react";
import { Route, Redirect } from "react-router-dom";
import { permissions } from "./config/pageContents";
import { getUserPermissions } from "./utils/storage";

export const PrivateRoute = ({ component: Component, type, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // check if route is restricted by role
      if (!getUserPermissions().includes(permissions[type])) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: "/" }} />;
      }
      // authorised so return component
      return <Component {...props} />;
    }}
  />
);
