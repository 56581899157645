import { permissions } from "../config/pageContents";
import { clearStorage } from "./storage";

export const logOut = () => {
  clearStorage();
  window.location.replace("/");
};

export const download = (res) => {
  const linkSource = `${process.env.REACT_APP_DOWNLOAD_ROOT}${res}`;
  const link = document.createElement("a");
  link.href = linkSource;
  link.download = `Report.xlsx`;
  link.click();
};

export const tryAwait = async (action, ...[...params]) => {
  try {
    const data = await action(...params);
    return [data, null];
  } catch (err) {
    return [null, err];
  }
};

export const getAccessible = (menu, user_permissions) =>
  menu.reduce((list, item) => {
    if (item.access) {
      if (user_permissions.includes(permissions[item.access])) {
        list.push(item);
      }
    } else {
      list.push(item);
    }
    return list;
  }, []);
