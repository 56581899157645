import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import LoginPage from "./pages/login/login";
import Router from "./router";
import { useSelector } from "react-redux";
import { getAccessToken } from "./utils/storage";
import Toast from "./components/mui-componenets/snackbar";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  const { authenticated } = useSelector((store) => store.auth);
  const access = getAccessToken();

  return (
    <BrowserRouter>
      <Toast/>
        <Route
          path="/"
          render={() =>
            authenticated || access ? (
              <Route component={Router} />
            ) : (
              <Route component={LoginPage} />
            )
          }
        />
    </BrowserRouter>
  );
}

export default App;
