import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setAlert } from "../../redux/slice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Toast() {
  const {
    alert: { open: show = false, message, type = "info" },
  } = useSelector((store) => store.menu);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setAlert());
  };

  if (!message) {
    return <span></span>;
  }

  return (
    <Snackbar open={show} autoHideDuration={2000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  )
}

Toast.propTypes = {
  type: PropTypes.oneOf(["success", "error", "warning", "info"]),
  open: PropTypes.bool,
  message: PropTypes.string,
};
