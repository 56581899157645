import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import CustomDialog from "./dialogue";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth";
import { initiateSyncDb } from "../../redux/slice";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
export default function DropDown({ icon, options = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [popup, setPopUp] = useState(false);
  const dispatch = useDispatch();
  const { syncData } = useSelector((store) => store.menu);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action, link) => {
    if (action === "logout") {
      setPopUp(true);
      return;
    }
    setAnchorEl(null);
    if (link) history.push(link);
    if (
      action === "syncDb" &&
      !syncData.track_id &&
      syncData.task_state !== "PENDING"
    ) {
      dispatch(initiateSyncDb());
    } else {
      setTimeout(() => {
        dispatch(syncData({ track_id: syncData.track_id }));
      }, 50000);
    }
  };

  const handleLogOut = () => {
    dispatch(logout());
    setPopUp(false);
  };

  return (
    <>
      <CustomDialog
        open={popup}
        title="Logout ?"
        onClose={() => setPopUp(false)}
        Actions={() => (
          <>
            <Button autoFocus onClick={() => setPopUp(false)} color="primary">
              No
            </Button>
            <Button onClick={handleLogOut} color="primary">
              Yes
            </Button>
          </>
        )}
      />
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        size={"medium"}
        color="secondary"
      >
        {icon}
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        elevation={0}
        getContentAnchorEl={null}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {options.map((option) => (
          <MenuItem
            key={option.title}
            onClick={() => handleClose(option.action, option.linkTo)}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.title} />
          </MenuItem>
        ))}
        {/* <MenuItem key="logout" onClick={() => setPopUp(true)}>
            <ListItemIcon ><PowerSettingsNewIcon/></ListItemIcon>
            <ListItemText primary="Log out" />
        </MenuItem> */}
      </StyledMenu>
    </>
  );
}
