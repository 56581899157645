import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API,
  CFP_PROFESSIONALS,
  COUPON_COUNT,
  COURSE_ENROLLMENT,
  EXAM_COUNT,
  GRADUATION_COUNT,
  PASS_COUNT,
  REGISTRATION_COUNT,
  SPECIALISTS,
  thunkHandler,
  UPDATED_ON,
} from "../../config/api/api";
import { generateGraphValues, getCourseWiseGraph } from "./utils";

const cpfProf = {};
const courseEnrollment = {};
const courseLevelEnrollment = {};
const passCount = {};
const examCount = {};
const initialState = {
  cpfProf,
  courseEnrollment,
  courseLevelEnrollment,
  passCount,
  examCount,
  couponCount: {},
  graduationCount: {},
  specialistsCount: {},
  registrationDetails: {},
};

export const getCPFGraph = createAsyncThunk("cpf/graph1", ( thunkAPI) =>
  thunkHandler(API.get(CFP_PROFESSIONALS), thunkAPI)
);

export const getCourseEnrollment = createAsyncThunk(
  "course/graph1",
  (query, thunkAPI) =>
    thunkHandler(API.get(COURSE_ENROLLMENT + query), thunkAPI)
);

export const getPassCount = createAsyncThunk(
  "course/count1",
  (query, thunkAPI) => thunkHandler(API.get(PASS_COUNT + query), thunkAPI)
);

export const getExamCount = createAsyncThunk(
  "course/count2",
  (query, thunkAPI) => thunkHandler(API.get(EXAM_COUNT + query), thunkAPI)
);

export const getCouponCount = createAsyncThunk(
  "coupon/count",
  (thunkAPI) =>
    thunkHandler(API.get(COUPON_COUNT), thunkAPI)
);

export const getGraduationCount = createAsyncThunk(
  "graduation/count",
  (query, thunkAPI) =>
    thunkHandler(API.get(GRADUATION_COUNT + query), thunkAPI)
);

export const getSpecialistsCount = createAsyncThunk(
  "specialists/count",
  (thunkAPI) =>
    thunkHandler(API.get(SPECIALISTS), thunkAPI)
);

export const getRegistrationCount = createAsyncThunk(
  "registrations/count",
  (query, thunkAPI) =>
    thunkHandler(API.get(REGISTRATION_COUNT + query), thunkAPI)
);
export const getLastUpdated = createAsyncThunk(
  "dashboard/last-updated-on/",
  (thunkAPI) =>
    thunkHandler(API.get(UPDATED_ON), thunkAPI)
);

export const dashboard = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [getCPFGraph.pending]: (state) => {
      state.graphsFetchingStarted = true;
      state.cpfProf = { ...cpfProf, loading: true };
    },
    [getCPFGraph.fulfilled]: (state, action) => {
      state.cpfProf = {
        ...state.cpfProf,
        title: action.payload.data.title,
        data: [generateGraphValues(action.payload.data.data)],
        loading: false,
      };
    },
    [getCPFGraph.rejected]: (state, action) => {
      state.cpfProf = {};
      state.hideCPF = true;
    },

    [getCourseEnrollment.pending]: (state) => {
      state.courseEnrollment = { ...courseEnrollment, loading: true };
      state.hideCourse = false;
    },
    [getCourseEnrollment.fulfilled]: (state, action) => {
      const response = action.payload.data.data;
      state.courseEnrollment = {
        ...courseEnrollment,
        title: action.payload.data.title,
        courseTitles: Object.keys(response.cumulative),
        data: [
          generateGraphValues(response.cumulative),
          ...getCourseWiseGraph(response.course_wise),
        ],
        loading: false,
      };
    },
    [getCourseEnrollment.rejected]: (state) => {
      state.courseEnrollment = {};
      state.hideCourse = true;
    },

    [getPassCount.pending]: (state) => {
      state.passCount = { ...passCount, loading: true };
    },
    [getPassCount.fulfilled]: (state, action) => {
      const response = action.payload.data.data;
      state.passCount = {
        ...passCount,
        title: action.payload.data.title,
        courseTitles: Object.keys(response.cumulative),
        data: [
          generateGraphValues(response.cumulative),
          ...getCourseWiseGraph(response.course_wise),
        ],
        loading: false,
      };
    },
    [getPassCount.rejected]: (state, action) => {
      state.passCount = {};
      state.hideCount = true;
    },

    [getExamCount.pending]: (state) => {
      state.examCount = { ...examCount, loading: true };
    },
    [getExamCount.fulfilled]: (state, action) => {
      const response = action.payload.data.data;
      state.examCount = {
        ...examCount,
        title: action.payload.data.title,
        courseTitles: Object.keys(response.cumulative),
        data: [
          generateGraphValues(response.cumulative),
          ...getCourseWiseGraph(response.course_wise),
        ],
        loading: false,
      };
    },
    [getExamCount.rejected]: (state, action) => {
      state.examCount = {};
      state.hideCount = true;
    },
    [getCouponCount.fulfilled]: (state, action) => {
      const response = action.payload.data.data;
      state.couponCount = {
        title: action.payload.data.title,
        courseTitles: Object.keys(response),
        data: [null, ...getCourseWiseGraph(response)],
        loading: false,
      };
    },
    [getGraduationCount.fulfilled]: (state, action) => {
      const response = action.payload.data.data;
      state.graduationCount = {
        title: action.payload.data.title,
        courseTitles: Object.keys(response.cumulative),
        data: [
          generateGraphValues(response.cumulative),
          ...getCourseWiseGraph(response.course_wise),
        ],
        loading: false,
      };
    },
    [getSpecialistsCount.fulfilled]: (state, action) => {
      const response = action.payload.data.data;
      state.specialistsCount = {
        title: action.payload.data.title,
        courseTitles: Object.keys(response),
        data: [null, ...getCourseWiseGraph(response)],
        loading: false,
      };
    },

    [getRegistrationCount.fulfilled]: (state, action) => {
      state.registrationDetails = action.payload.data;
    },
    [getLastUpdated.fulfilled]: (state, action) => {
      state.updatedOn = action.payload.data;
    }
  },
});

// Action creators are generated for each case reducer function

export default dashboard.reducer;
