import React, { useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import DropDown from "./dropdown";
import BadgeIcon from "./badge_icon";
import { permissions } from "../../config/pageContents";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    height: "4.5rem",
    "& header": {
      zIndex: 1201,
      transition:
        "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      paddingRight: "0px !important",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerButton: {
    width: "200px",
    marginBottom: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function AppHeader({ onSideBarClose, navMenus }) {
  const classes = useStyles();
  const [drawer, setShowDrawer] = useState(false);

  const toggleSidebar = (open) => {
    onSideBarClose(open);
    setShowDrawer(open);
  };

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          {onSideBarClose && (
            <div className={classes.sectionMobile}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={() => toggleSidebar(!drawer)}
              >
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <img
            src={require(`../../assets/logo.png`).default}
            className={classes.headerButton}
            alt="brand"
          />
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}
          <div className={classes.grow} />
          {/* <Avatar alt="Remy Sharp" src="https://secure.gravatar.com/avatar/655694b5e566d31124e8dc43db9b567c?s=32&#038;d=mm&#038;r=g" /> */}
          {onSideBarClose && (
            <div>
              {navMenus.map((item) =>
                item.dropdown ? (
                  <DropDown key={item.title} {...item} />
                ) : item.navVisible ? (
                  <BadgeIcon key={item.title} {...item} />
                ) : null
              )}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
