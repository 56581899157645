import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, makeStyles, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

export const StyledSkeleton = withStyles((theme) => ({}))((props) => (
  <Skeleton animation="wave" style={{ background: "white" }} {...props} />
));
const useStyles = makeStyles(() => ({
  root: {
    overflow: "hidden",
    display: "flex",
  },
  item: {
    maxWidth: 400,
  },
  leftMenu: {
    padding: 20,
  },
  menuItem: {
    padding: 20,
  },
}));
export default function PageLoader() {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.leftMenu}>
        {[1, 2, 3, 4, 5].map((y) => (
          <Box margin={1} key={y} className={styles.menuItem}>
            <StyledSkeleton variant="circle">
              <Avatar />
            </StyledSkeleton>
          </Box>
        ))}
      </div>
      <Grid container spacing={3} direction="row">
        {[1, 2, 3, 6, 7].map((x) => (
          <Grid item xs={12} sm={4} key={x} className={styles.item}>
            <Box>
              <Box margin={1}>
                <StyledSkeleton variant="circle">
                  <Avatar />
                </StyledSkeleton>
              </Box>
              <Box width="100%">
                <StyledSkeleton width="100%">
                  <Typography>.</Typography>
                </StyledSkeleton>
              </Box>
            </Box>
            <StyledSkeleton variant="rect" width="100%">
              <div style={{ paddingTop: "57%" }} />
            </StyledSkeleton>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
