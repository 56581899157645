import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import CustomDialog from "./dialogue";
import { useDispatch, useSelector } from "react-redux";
import { selectMenu } from "../../redux/slice";
import ListIcon from "./listIcon";
import { getSuperSetUrl } from "../../utils/storage";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    marginRight: theme.spacing(2),
    flexShrink: 0,
    whiteSpace: "nowrap",
    "& .MuiDrawer-paperAnchorDockedLeft": {
      border: "none",
    },
    [theme.breakpoints.up("xs")]: {
      width: 45,
    },
  },
  minimise: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
      width: drawerWidth,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [theme.breakpoints.between("sm")]: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(9) + 1,
    },
  },
  menuList: {
    paddingTop: 64,
    overflow: "auto",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
  },
  itemLabel: {
    color: theme.palette.primary.main,
    display: "block",
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  menu: {
    flexDirection: "column",
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "white",
    },
  },
  menuIcon: {
    background: theme.palette.primary.smudge,
    width: "44px",
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "17px",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
    "&:hover": {
      boxShadow: "0px 8px 15px #d2e5ff",
    },
  },
  selectedMenuIcon: {
    background: theme.palette.primary.main,
    width: "44px",
    height: "44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "17px",
    boxShadow: "0px 8px 15px #d2e5ff",
    "& .MuiSvgIcon-root": {
      color: theme.palette.secondary.main,
    },
  },
  logout: {
    display: "flex",
    justifyContent: "center",
    marginTop: 35,
    cursor: "pointer",
  },
  logoutText: {
    color: theme.palette.text.primary,
    paddingLeft: 12,
    fontWeight: 600,
    fontSize: 15.5,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function MiniDrawer({ menu = [], minimise }) {
  const history = useHistory();
  const { currentPage } = useSelector((store) => store.menu);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState(
    currentPage ? currentPage : history.location.pathname
  );
  const [popup, setPopUp] = useState(false);

  useEffect(() => {
    if (currentPage) setSelected(currentPage);
  }, [currentPage]);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const onPopUpClose = () => {
    setPopUp(false);
    dispatch(selectMenu(history.location.pathname));
  };
  const handleLogout = () => {
    if(currentPage === "isSuperset"){
      window.open(getSuperSetUrl(), "_blank");
    } else {
      window.open(currentPage, "_blank");
    }
    onPopUpClose();
  };
  const onNaviagte = (loc) => {
    dispatch(selectMenu(loc));
    history.push(loc);
  };
  const redirectPage = (loc, msg) => {
    dispatch(selectMenu(loc));
    setPopUp(msg);
  };
  return (
    <>
      <CustomDialog
        open={popup ? true : false}
        title={popup}
        onClose={() => onPopUpClose()}
        Actions={() => (
          <>
            <Button
              autoFocus
              onClick={() => onPopUpClose()}
              color="primary"
              variant="outlined"
            >
              No
            </Button>
            <Button onClick={handleLogout} color="primary" variant="contained">
              Yes
            </Button>
          </>
        )}
      />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.minimise]: minimise,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.minimise]: minimise,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List className={classes.menuList}>
          {menu.map((item) =>
            item.options ? (
              item.options.map((option) => (
                <ListIcon key={option.title} {...option} onNaviagte={onNaviagte} redirectPage={redirectPage} classes={classes} selected={selected}/>
              ))
            ) : (
              <ListIcon key={item.title} {...item} onNaviagte={onNaviagte} redirectPage={redirectPage} classes={classes} selected={selected}/>
            )
          )}
          {/* <ListItem
            button
            key={"Logout"}
            className={clsx(classes.menu, classes.sectionMobile)}
            disableRipple={true}
            onClick={() => setPopUp(true)}
          >
            <div
              className={
                "/logout" === selected ? classes.selectedMenuIcon : classes.menuIcon
              }
            >
              <PowerSettingsNewIcon size="small" />
            </div>
            <ListItemText
              primary={
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.itemLabel}
                  color="textPrimary"
                >
                  {"Logout"}
                </Typography>
              }
            />
          </ListItem> */}
        </List>
        {/* <div
          className={clsx(classes.sectionDesktop, classes.logout)}
          onClick={() => setPopUp(true)}
        >
          <PowerSettingsNewIcon size="small" />
          <Typography
            component="span"
            variant="body1"
            className={classes.logoutText}
            color="textPrimary"
          >
            Log out
          </Typography>
        </div> */}
      </Drawer>
    </>
  );
}
