import { colors } from "../../config/pageContents";

const getRandom = () => Math.floor(Math.random() * 256);

function colorGenerator() {
  return `rgb(${getRandom()}, ${getRandom()},${getRandom()})`;
}

const shuffled = (unshuffled) =>
  unshuffled
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

export const generateGraphValues = (data, defaultColor) => {
  let graphVals = [];
  const _colors = defaultColor ? colors : shuffled(colors);
  for (const [key, value] of Object.entries(data)) {
    graphVals.push({
      name: `${key.replace("FPSB® ", "").slice(0, 24)}...`,
      color: _colors[graphVals.length] ?? colorGenerator(),
      value,
      title: key,
    });
  }
  return graphVals;
};

export const getCourseWiseGraph = (data) => {
  const graphData = [];
  data && Object.values(data).forEach((c) => graphData.push(generateGraphValues(c, true)));
  return graphData;
};
