import React, { Suspense, lazy } from "react";
import { Route } from "react-router-dom";
import PageLoader from "./components/loaders/pageLoader";
import Layout from "./components/layout/layout";
import locations from "./locations";
import { PrivateRoute } from "./privateRoute";
const Home = lazy(() => import("./pages/home/home"));
const Import = lazy(() => import("./pages/import/importOptions"));
const ImportList = lazy(() => import("./pages/import/importList"));
const Log = lazy(() => import("./pages/log/log"));
const FileDetails = lazy(() => import("./pages/import/importedFile"));
const Settings = lazy(() => import("./pages/settings/settings"));
export default function Router() {
  return (
    <Layout>
      <Suspense fallback={<PageLoader />}>
        <Route exact path={locations.dashboard} component={Home} />
        <PrivateRoute exact path={locations.import} component={Import} type="import" />
        <Route exact path={locations.pendingImport} component={ImportList} />
        <Route exact path={locations.log} component={Log} />
        <Route exact path={locations.fileDetails} component={FileDetails} />
        <Route exact path={locations.settings} component={Settings} />
      </Suspense>
    </Layout>
  );
}
