import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API, SYNC_DB, thunkHandler } from "../config/api/api";
import { approveReport, getExamReports } from "../pages/import/slice";
import { login, refreshAccessToken } from "./auth";

const initialState = {
  currentPage: "",
  syncData: {},
  alert: { open: false },
};

export const syncDB = createAsyncThunk("sync/db", (payload, thunkAPI) =>
  thunkHandler(API.post(SYNC_DB, { ...payload }), thunkAPI)
);

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    selectMenu: (state, action) => {
      state.currentPage = action.payload;
    },
    setAlert: (state, action) => {
      state.alert = {
        open: !state.alert.open,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [syncDB.fulfilled]: (state, action) => {
      state.syncData = action.payload.data;
    },
    [syncDB.rejected]: (state, action) => {
      state.alert = {
        open: true,
        message: "Failed to sync data",
        type: "error",
      };
    },
    [login.rejected]: (state) => {
      state.alert = {
        open: true,
        message: "Login failed",
        type: "error"
      };
    },
    [refreshAccessToken.rejected]: (state) => {
      state.alert = {
        open: true,
        message: "Session expired",
      };
    },
    [getExamReports.rejected]: (state, action) => {
      state.alert = {
        open: true,
        message: "Server error",
        type: "error",
      };
    },
    [approveReport.fulfilled]: (state, action) => {
      state.alert = {
        open: true,
        message: "Report approved",
        type: "success",
      };
    },
    [approveReport.rejected]: (state, action) => {
      state.alert = {
        open: true,
        message: "Failed to approve",
        type: "error",
      };
    },
  },
});
export const initiateSyncDb = () => {
  return (dispatch) => {
    return API.get(SYNC_DB)
      .then((res) => {
        if (res.data.data) {
          dispatch(syncDB(res.data.data));
          dispatch(setAlert({ message: res.data.message || "Sync db started"}))
        }
      })
      .catch(
        (e) => dispatch(setAlert({ message: "Failed to sync data", type: "error" }))
      );
  };
};
// Action creators are generated for each case reducer function
export const { selectMenu, setAlert } = menuSlice.actions;

export default menuSlice.reducer;
