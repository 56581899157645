import { logOut } from "../../utils/utilities";

export function onResponseError(error) {
    if (error && error.response && error.response.status === 401) {
        logOut();
    }
    if (error && error.response && error.response.data) {
        return Promise.reject(error.response.data);
    } else {
        let _err = {
            message: 'Something went wrong.Please try again',
        };
        return Promise.reject(_err);
    }
}

export function onResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
    } else {
        return onResponseError(response);
    }
}