import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";
import { CircularProgress, Typography } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { useDispatch, useSelector } from "react-redux";
import { dummyLogin, login, refreshAccessToken } from "../../redux/auth";
import { useHistory } from "react-router-dom";
import { API, GET_REDIRECT_URL, TOKEN } from "../../config/api/api";
import { getRefreshToken } from "../../utils/storage";
import { Alert } from "@material-ui/lab";
import locations from "../../locations";

export default function Login() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { isLoading, authError } = useSelector((store) => store.auth);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const handleLogin = (e) => {
    if (!redirectUrl) {
      API.get(GET_REDIRECT_URL)
        .then((res) => {
          setLoading(false);
          window.location.href = res.data.data?.url
        })
        .catch((e) => {
          setLoading(false);
          setErr(true);
        });
        return
    }
    window.location.href = redirectUrl;
  };
  const history = useHistory();
  useEffect(() => {
    let code = history.location.search?.split("=")?.[1];
    if (code) {
      dispatch(login(code)).then(res => history.push(locations.dashboard))
    } else {
      // const refreshToken = getRefreshToken();
      // if (refreshToken) {
      //   dispatch(refreshAccessToken({ refresh: refreshToken }));
      //   return;
      // }
      // API.post(TOKEN, {
      //   email: "abhi@analysedigital.com",
      //   password: "abhi@analysedigital.com",
      // })
      //   .then((res) => {
      //     dispatch(dummyLogin(res.data))
      //   })
      //   .catch((err) => console.log("err", err));
      API.get(GET_REDIRECT_URL)
        .then((res) => {
          setRedirectUrl(res.data.data?.url);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          setErr(true);
        });
    }
  }, []);

  if (loading || isLoading) {
    return (
      <div className={styles.root}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className={styles.root}>
        <form noValidate autoComplete="off" className={styles.form}>
          <div className={styles.imageDiv}>
            <img
              className={styles.responsiveImg}
              src={require("../../assets/logo.png").default}
              alt="<3"
            />
          </div>
          <div className={styles.content}>
            <div className="hover-underline-animation">
              <Typography variant="h5" display="block" gutterBottom>
                Signin
              </Typography>
            </div>
            <Typography display="block" className={styles.infoTxt}>
              Click on below button to login to your sso account.
            </Typography>
            <div className={styles.margin}>
              <Button
                variant="outlined"
                className={styles.loginBtn}
                endIcon={<DoubleArrowIcon />}
                onClick={handleLogin}
              >
                Authorize to proceed
              </Button>
            </div>
            {err && (
              <Alert severity="error">
                Server error! Failed to fetch the redirect URL.
              </Alert>
            )}
            {authError && (
              <Alert severity="error">Server error! Failed to login.</Alert>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
