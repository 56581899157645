import { getAccessToken } from "../../utils/storage";

export const onRequest = (config) => {
    const headers = getAccessToken() ? { Authorization: `Bearer ${getAccessToken()}` } : {};
    let request = {
        method: 'GET',
        ...config,
        headers: {
            'Content-Type': 'application/json',
            ...config.headers,
            ...headers,
        },
    };
    if (config.headers['Content-Type'] === 'multipart/form-data') {
        request = {
            method: 'GET',
            ...config,
            headers: {
                'Content-Type': undefined,
                ...config.headers,
                ...headers,
            },
        };
        delete request.headers['Content-Type'];
    }
    return request;
};

export const onRequestError = (error) => Promise.reject(error);
