import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API,
  LOGIN,
  REFRESH_ACCESS_TOKEN,
  thunkHandler,
} from "../config/api/api";
import config from "../config/config";
import { menuItems, navMenus } from "../config/pageContents";
import {
  clearStorage,
  getUserPermissions,
  removeCookie,
  setCookie,
} from "../utils/storage";
import { getAccessible } from "../utils/utilities";

const initialState = {
  authenticated: false,
  token: "",
  isLoading: false,
  authError: false,
  userData: {},
  userPermissions: getUserPermissions() || null,
  menu: [],
  navMenu: [],
};

export const login = createAsyncThunk("auth/login", (payload, thunkAPI) =>
  thunkHandler(API.get(`${LOGIN}?code=${payload}`), thunkAPI)
);
// export const reFetch = createAsyncThunk("auth/login", (payload, thunkAPI) =>
//   thunkHandler(API.get(`${LOGIN}?code=${payload}`), thunkAPI)
// );
export const refreshAccessToken = createAsyncThunk(
  "auth/access",
  (payload, thunkAPI) =>
    thunkHandler(API.post(REFRESH_ACCESS_TOKEN, payload), thunkAPI)
);

export const counterSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      clearStorage();
      state.authenticated = false;
      window.location.href = "/";
    },
    dummyLogin: (state, action) => {
      setCookie(config.SESSION_AUTH, action.payload.access, 1);
      setCookie(config.SESSION_REFRESH, action.payload.refresh);
      state.authenticated = true;
    },
    getUserMenu: (state) => {
      const user_permissions = state.userPermissions;
      let navMenu = [...navMenus];
      let _options = [];
      navMenu.map(({ options }) => {
        options.map((x) => {
          _options.push(x);
        });
      });
      state.menu = getAccessible(menuItems, user_permissions);
      state.navMenu = [
        ...state.navMenu,
        {
          ...navMenus[0],
          options: getAccessible(
            _options.map((x) => ({ ...x })),
            user_permissions
          ),
        },
      ];
    },
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.isLoading = true;
    },
    [login.fulfilled]: (state, action) => {
      const {
        resource = {},
        auth: { access, refresh },
        superset_url,
        middleware_permissions,
      } = action.payload?.data;
      if (middleware_permissions.includes("middleware_view")) {
        setCookie(config.SESSION_AUTH, access, 1);
        setCookie(config.SESSION_REFRESH, refresh);
        setCookie(config.USER_DETAILS, resource);
        setCookie(config.SUPERSET_URL, superset_url);
        setCookie(config.USER_PERMISSION, middleware_permissions);
        state.userPermissions = middleware_permissions;
        state.isLoading = true;
        state.authenticated = true;
        state.userData = resource;
      } else {
        state.isLoading = false;
        state.authError = true;
        state.authenticated = false;
      }
    },
    [login.rejected]: (state, action) => {
      state.isLoading = false;
      state.authError = true;
      state.authenticated = false;
      state.userData = {};
    },
    [refreshAccessToken.pending]: (state) => {
      state.isLoading = true;
    },
    [refreshAccessToken.fulfilled]: (state, action) => {
      setCookie(config.SESSION_AUTH, action.payload.access, 1);
      state.authenticated = true;
      state.isLoading = false;
    },
    [refreshAccessToken.rejected]: (state) => {
      state.authenticated = false;
      state.isLoading = false;
      removeCookie(config.SESSION_REFRESH);
    },
  },
});

// Action creators are generated for each case reducer function
export const { logout, dummyLogin, getUserMenu } = counterSlice.actions;

export default counterSlice.reducer;
