import { alpha, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    // flexWrap: 'wrap',
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    background: `linear-gradient(45deg, #5a7bfc1c 30%, #3c95ec70 90%)`,
    // paddingTop: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(3),
    // minHeight: 400,
    width: '100%',
    maxWidth: 368,
    background: `linear-gradient(45deg, #3494E6 30%, ${theme.palette.primary.main} 90%)`,
    display: "flex",
    alignItems: 'center',
    flexDirection: "column",
    borderRadius: 25,
    // justifyContent: "center",
    height: '500px',
    // height: "calc(90vh - 285px)",
    // [theme.breakpoints.up("md")]: {
    //   justifyContent: "center",
    //   height: "90vh"
    // },
    "& .MuiButton-root": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      borderRadius: 25,
      whiteSpace: "nowrap"
    }
  },
  content: {
    display: "flex",
    alignItems: 'center',
    flexDirection: "column",
    justifyContent: "center",
    height: '100%'
  },
  imageDiv: {
    width: "100%",
  },
  responsiveImg: {
    width: "100%",
    height: "auto",
    maxWidth: 250
  },
  infoTxt: {
    color: theme.palette.secondary.main,
    fontSize: "0.86rem",
    paddingTop: theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(3),
  },
  shadow: {
    boxShadow: `0 3px 5px 2px ${theme.palette.primary.main}`,
  },
  loginBtn: {
    width: '100%',
    textTransform: "none",
    fontSize: "1.1rem",
  },
  forgotText: {
    color: theme.palette.text.primary,
    float: 'right',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(3)
  }
}));
