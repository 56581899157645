import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import menuReducer from "./slice";
import importsReducer from "../pages/import/slice";
import logsReducer from "../pages/log/slice";
import dashboard from "../pages/home/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    imports: importsReducer,
    logs: logsReducer,
    dashboard: dashboard,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
