import react, { memo } from "react";
import Icon from "../iconHandler/icon";
import { ListItem, ListItemText, Typography } from "@material-ui/core";

const CustomListItem = ({
  title,
  linkTo,
  icon,
  showAlert,
  message,
  onNaviagte,
  redirectPage,
  classes,
  selected
}) => {

  return (
    <ListItem
      button
      key={title}
      className={classes.menu}
      disableRipple={true}
      onClick={() =>
        showAlert ? redirectPage(linkTo, message) : onNaviagte(linkTo)
      }
    >
      <div
        className={
          linkTo === selected ? classes.selectedMenuIcon : classes.menuIcon
        }
      >
        {<Icon name={icon} />}
      </div>
      <ListItemText
        primary={
          <Typography
            component="span"
            variant="body2"
            className={classes.itemLabel}
            color="textPrimary"
          >
            {title}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default memo(CustomListItem);
