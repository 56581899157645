import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import BookIcon from "@material-ui/icons/Book";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";

export default function Icons({name, ...props}) {
  switch (name) {
    case "home":
      return <HomeIcon size="medium" {...props} />;
    case "superSet":
      return <SupervisedUserCircleIcon size="medium" {...props}/>;
    case "import":
      return <ImportExportIcon size="medium" {...props}/>;
    case "settings":
      return <SettingsIcon size="medium" {...props}/>;
    case "log":
      return <BookIcon size="medium" {...props}/>;
    case "exit":
      return <ExitToAppIcon size="medium" {...props}/>;
    default:
      return "";
  }
}
