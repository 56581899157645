import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API,
  LOGS,
  thunkHandler,
} from "../../config/api/api";

const initialState = {
    isLoading: true,
    data: {},
    fulfilled: false,
};

export const logs = createAsyncThunk("audit/log", (page, thunkAPI) =>
  thunkHandler(API.get(`${LOGS}?page=${page}`), thunkAPI)
);

export const counterSlice = createSlice({
  name: "logs",
  initialState,
  extraReducers: {
    [logs.pending]: (state, action) => {
        state.isLoading = true;
    },
    [logs.fulfilled]: (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.fulfilled = true;
    },
    [logs.rejected]: (state, action) => {
        state.isLoading = false;
    },
  },
});

export default counterSlice.reducer;
