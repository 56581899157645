import Cookies from "js-cookie";
import config from "../config/config";

export const setCookie = (name, data, expire) =>
  Cookies.set(name, JSON.stringify(data), { expires: expire });

export const getCookie = (name) => {
  const data = Cookies.get(name);
  if (data) return JSON.parse(data);
  return null;
};

export const removeCookie = (name, ...rest) => Cookies.remove(name, ...rest);

export const getAccessToken = () => getCookie(config.SESSION_AUTH);
export const getRefreshToken = () => getCookie(config.SESSION_REFRESH);
export const getUserData = () => getCookie(config.USER_DETAILS);
export const getSuperSetUrl = () => getCookie(config.SUPERSET_URL);
export const getUserPermissions = () => getCookie(config.USER_PERMISSION)?? [];

export const clearStorage = () => {
  removeCookie(config.SESSION_AUTH);
  removeCookie(config.SESSION_REFRESH);
  removeCookie(config.SUPERSET_URL);
  removeCookie(config.USER_DETAILS);
  removeCookie(config.USER_PERMISSION);
}