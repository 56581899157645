import axios from "axios";
import { onRequest, onRequestError } from "./requestInterceptors";
import { onResponse, onResponseError } from "./responseInterceptors";

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  timeout: 40000
});
API.interceptors.request.use(onRequest, onRequestError);
API.interceptors.response.use(onResponse, onResponseError);

export const thunkHandler = async (asyncFn, thunkAPI) => {
  try {
    const response = await asyncFn;
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message || "Something went wrong");
  }
};

export const GET_REDIRECT_URL = "sso-redirect/";
export const LOGIN = "sso-login/";
export const TOKEN = "token/";
export const REFRESH_ACCESS_TOKEN = "token/refresh/";

export const SYNC_DB = "sync-db/";

export const USERS = "users/?permissions=middleware_checker";
export const UPLOAD_REPORT = "upload-report/";
export const EXAM_REPORTS = "exam-reports/";
export const TEMPLATE_DOWNLOAD = "report-template/";

export const LOGS = "logs/";
export const CFP_PROFESSIONALS = "dashboard/cfp-professionals/";
export const COURSE_ENROLLMENT = "dashboard/course-enrollment/";
export const COURSE_LEVEL_ENROLLEMENT = "dashboard/course-level-enrollment/";
export const EXAM_COUNT = "dashboard/exam-count/";
export const PASS_COUNT = "dashboard/pass-count/";
export const COUPON_COUNT = "dashboard/coupon-count/";
export const GRADUATION_COUNT = "dashboard/graduation-count/";
export const SPECIALISTS = "dashboard/specialists/";
export const REGISTRATION_COUNT = "dashboard/registrations/";
export const UPDATED_ON = "dashboard/last-updated-on/";