import { AccountCircle } from "@material-ui/icons";
import SyncIcon from "@material-ui/icons/Sync";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import locations from "../locations";

export const menuItems = [
  {
    title: "Dashboard",
    linkTo: locations.dashboard,
    icon: "home",
    navVisible: false,
  },
  {
    title: "Super Set",
    icon: "superSet",
    access: "superset",
    navVisible: false,
    showAlert: true,
    message: "Redirect to Super Set?",
    linkTo: 'isSuperset',
  },
  {
    title: "Import",
    access: "import",
    icon: "import",
    linkTo: locations.import,
    navVisible: false,
  },
  {
    title: "Settings",
    icon: "settings",
    linkTo: locations.settings,
    navVisible: false,
  },
  {
    title: "Audit Log",
    icon: "log",
    access: "auditLog",
    navVisible: false,
    linkTo: locations.log,
  },
  {
    title: "Exit to WordPress",
    showAlert: true,
    message: "Exit to WordPress?",
    linkTo: process.env.REACT_APP_WORDPRESS,
    icon: "exit",
    navVisible: false,
  },
];
export const navMenus = [
  {
    title: "user",
    dropdown: true,
    icon: <AccountCircle />,
    options: [
      {
        title: "Sync Db",
        icon: <SyncIcon size="medium" />,
        action: "syncDb",
        access: "syncDb",
      },
      {
        title: "Logout",
        icon: <PowerSettingsNewIcon size="medium" />,
        action: "logout",
      },
    ],
  },
];
export const dashboardMessages = {
  pendingReport: "Don't forget to check on to your pending imports.",
};
export const permissions = {
  middleware_view: "middleware_view",
  import: "middleware_maker",
  superset: "superset_view",
  auditLog: "auditlog_view",
  syncDb: "sync_db",
}
export const footerLinks = [
  { title: "Contact us", linkTo: "/" },
  { title: "copyright", linkTo: "/" },
  { title: "Privacy policy", linkTo: "/" },
  { title: "Sitemap", linkTo: "/" },
];
export const colors = [
  "#2193b0",
  "#99f2c8",
  "#5A7BFC",
  "#ADBEFE",
  "#3c95ec",
  "#4286f4",
  "rgb(119 60 236 / 52%)",
  "rgb(60 98 236 / 52%)",
  "#C6FFDD",
  "#4A00E0",
];
